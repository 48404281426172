import axios from "axios";
import { message } from "antd";
import CONFIG from "@/config";
import { getStorage } from "@/utils/common";

const config = Object.assign(
  {
    baseURL: process.env.NODE_ENV == "development" ? "/api" : "/api", // api 的 base_url
    timeout: 600000,
  },
);
const service = axios.create(config);
service.interceptors.request.use(
  (config) => {
    const channelid = CONFIG.CHANNELID;
    config.headers = {
      ...config.headers,
      channelid,
    };
    if (getStorage("token")) {
      config.headers["Authorization"] = "Bearer " + getStorage("token");
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      if (response.data.code === 0 || response.data.code === 200 || !response.data.msg) {
        resolve(response);
      } else {
        // message.error(`接口:${response.config.url}错误信息===>`, response.data.msg);
        message.error(response.data.msg, 1);
        reject(response);
      }
    });
  },
  (error) => {
    // 对响应错误做点什么
    try {
      message.error(CONFIG.ERRORINFO[error.response.status]);
    } catch (err) {
      console.log(err);
    }
    return Promise.reject(error);
  }
);

export default service;
