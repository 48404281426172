import React from 'react';
import Style from './index.module.less';
import { WaterYear, WaterLevel, StationMap, MainTarget } from "./module";
import WebsocketDom from "@/components/websocketDom";
function QcWaterLevel() {
    return <div className={`${Style.qcWaterLevelWrapper} flex flex-row h100`}>
        <div className={`${Style[`qcPowerStation-wrapper_left`]} flex flex-colum`}>
            <div className={`${Style.qcWaterLevelWrapperHd} flex`}>
                <WaterYear />
                <WaterLevel />
            </div>
            <div className='flex-1'>
                <StationMap />
            </div>
        </div>
        <div className={`${Style[`qcPowerStation-wrapper_right`]} flex flex-colum flex-1`}>
            <MainTarget />
        </div>
        {/*websocket部分*/}
        <WebsocketDom />
    </div>
}


export default QcWaterLevel