// import COFGING from "@/config";
const COFGING = {
  STATIONS: {
    //横山
    1806: {
      stcd: "1806",
      stnm: "柏坑水库",
      pos: {
        tpos: "r",
        x: 427,
        y: 342,
      },
    },
    1807: {
      stcd: "1807",
      stnm: "南溪口",
      pos: {
        tpos: "r",
        x: 636,
        y: 376,
      },
    },
    1809: {
      stcd: "1809",
      stnm: "寺坑",
      pos: {
        tpos: "r",
        x: 539,
        y: 563,
      },
    },
    1850: {
      stcd: "1850",
      stnm: "董家雨量站",
      pos: {
        tpos: "r",
        x: 245,
        y: 221,
      },
    },
    1811: {
      stcd: "1811",
      stnm: "横山坝上",
      pos: {
        tpos: "l",
        x: 731,
        y: 454,
      },
    },
    1852: {
      stcd: "1852",
      stnm: "箭岭下雨量",
      pos: {
        tpos: "r",
        x: 447,
        y: 472,
      },
    },
    1901: {
      stcd: "1901",
      stnm: "横山水库雨量",
      pos: {
        tpos: "r",
        x: 768,
        y: 454,
      },
    },
  }
}
export function addPos(o) {
  // 增加柱形颜色
  o.theme = judgeRain(o);
  // 和高度计算
  o.theme.height = (o.val * 150) / 250 < 38 ? 38 : (o.val * 150) / 250;
  // 增加定位
  o.pos = (COFGING.STATIONS[o.stcd] && COFGING.STATIONS[o.stcd].pos) || null;
}

function judgeRain(o) {
  //判断雨量
  const val = o.val;
  if (val >= 250) {
    //大暴雨
    return {
      borderColor: "#AB024D",
      fillColor:
        "linear-gradient(to top, rgba(171, 2, 77, 1), rgba(171, 2, 77, 0.1))",
      txtFillColor: "linear-gradient(0deg, #FF1A80, #A30049)",
    };
  } else if (100 < val && val < 250) {
    // 大暴雨
    return {
      borderColor: "#D318E6",
      fillColor:
        "linear-gradient(to top, rgba(211, 24, 230, 1), rgba(211, 24, 230, 0.1))",
      txtFillColor: "linear-gradient(0deg, #D318E6, #91069F)",
    };
  } else if (50 < val && val < 100) {
    // 暴雨
    return {
      borderColor: "#2C36FD",
      fillColor:
        "linear-gradient(to top, rgba(44, 54, 253, 1), rgba(44, 54, 253,0.1))",
      txtFillColor: "linear-gradient(0deg, #313CFB, #0A14BC)",
    };
  } else if (25 < val && val <= 50) {
    // 大雨
    return {
      borderColor: "#248CF2",
      fillColor:
        "linear-gradient(to top, rgba(69, 205, 255, 1), rgba(69, 205, 255, 0.1))",
      txtFillColor: "linear-gradient(0deg, #45CDFF, #278DF2)",
    };
  } else if (10 < val && val <= 25) {
    // 中雨
    return {
      borderColor: "#10CB94",
      fillColor:
        "linear-gradient(to top, rgba(16, 203, 148, 1), rgba(16, 203, 148, 0.1))",
      txtFillColor: "linear-gradient(0deg, #1CF8B7, #019166)",
    };
  } else if (0 < val && val <= 10) {
    // 小雨
    return {
      borderColor: "#6BD636",
      fillColor:
        "linear-gradient(to top, rgba(107, 214, 54, 1), rgba(107, 214, 54, 0.1))",
      txtFillColor: "linear-gradient(0deg, #82FE4A, #42B011)",
    };
  } else {
    return {
      borderColor: "#ffdda8",
      fillColor:
        "linear-gradient(to top, rgba(107, 214, 54, 1), rgba(107, 214, 54, 0.1))",
      txtFillColor: "linear-gradient(0deg, #82FE4A, #42B011)",
    };
  }
}

// 地图标记颜色和大小值
export const mapTag = [
  {
    color: "#AB024D",
    size: "特大暴雨（≥250）",
  },
  {
    color: "#D318E6",
    size: "大暴雨（100-250）",
  },
  {
    color: "#2C36FD",
    size: "暴雨（50-100）",
  },
  {
    color: "#248CF2",
    size: "大雨（25-50）",
  },
  {
    color: "#10CB94",
    size: "中雨（10-25）",
  },
  {
    color: "#6BD636",
    size: "小雨（0-10）",
  },
  {
    color: "rgb(255, 221, 168)",
    size: "无雨（0）",
  },
];
