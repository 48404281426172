import React from "react";
import 'core-js/es';
import ReactDOM from "react-dom";
import "./index.less";
import App from "./App";
import { RecoilRoot } from "recoil";
import echarts from "echarts/lib/echarts";
import 'polyfill-object.fromentries';
import "@/assets/icons";
ReactDOM.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  document.getElementById("root")
);
