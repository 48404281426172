import request from "../utils/request";
import qs from "qs";
import CONFIG from "@/config";
// 雨量信息Api
export async function getRainInfo(data) {
  return request(`/rw/info/${data.ID}`, {
    method: "get",
  });
}

// 雨量信息
export async function getDayRainInfo(data) {
  return request(`/rw/dayrain/${data.ID}`, {
    method: "get",
  });
}

// 水位过程线
export async function getHourInfo(data) {
  return request(`/rw/hour/${data.ID}`, {
    method: "get",
  });
}

// 十日水位
export async function getTenDayInfo(data) {
  return request(`/rw/tenday/${data.ID}`, {
    method: "get",
  });
}

// 降雨预报
export async function getRainForecast() {
  return request("/bs/rainForecast ", {
    method: "get",
  });
}

// 近三天值班表
export async function getDuty() {
  return request("/bs/getDuty ", {
    method: "get",
  });
}

// 获取水质
export async function getInfo() {
  return request("/bs/getInfo ", {
    method: "get",
  });
}

// 供水信息
export async function getWaterSupply() {
  return request("/bs/waterSupply ", {
    method: "get",
  });
}
//嵊州供水信息
export async function getWaterSupply2() {
  return request("/bs/waterSupplyOfSZ ", {
    method: "get",
  });
}
// 供水站点信息
export async function getStationRain() {
  return request("/bs/stationRain ", {
    method: "get",
  });
}

// 登录
export async function setLogin(data) {
  return request("/bs/login", {
    method: "get",
    params: data,
  });
}
// 上传文件
export async function uploadDuty(query) {
  return request("/bs/uploadDuty", {
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,
  });
}

// 上传文件列表
export async function getFileList(query) {
  return request("/bs/files", {
    method: "get",
  });
}
// 文件删除
export async function delTempFile(query) {
  return request("/bs/file/" + query, {
    method: "delete",
  });
}
// 更新信息

export async function updateInfo(query) {
  return request("/bs/updateInfo", {
    method: "post",
    data: query,
  });
}

// 上传临时文件列表
export async function uploadFileLin(query) {
  return request("/bs/uploadFile", {
    method: "post",
    timeout: 900000, // request timeout
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: query,
  });
}

// 更新大屏切换

export async function uploadSwitch(query) {
  return request("/bs/uploadSwitch", {
    method: "post",
    data: qs.stringify(query),
  });
}

// 获取大屏自动切换信息
export async function getSwitch(query) {
  return request("/bs/getSwitch", {
    method: "get",
  });
}

// 删除
export async function deleteFile(query) {
  return request(`/bs/file/${query.fileName}`, {
    method: "delete",
  });
}

// 水库流量
export async function getDtu(query) {
  return request(`/bs/ecoFlow`, {
    method: "get",
    // noApi: true,
  });
}

// // 下流水流量
// export async function getEcolAndPower(query) {
//   return request(``, {
//     method: "get",
//     baseURL: "",
//     // noApi: true,
//   });
// }

// 获取平台配置信息
export async function getSystemConfig(query) {
  return request(`/bs/config`, {
    method: "get",
    // noApi: true,
  });
}

// 流量--config配置(json的key)里面获取 溪下
export async function getFlowByName(query) {
  return request(`/bs/getFlowByName`, {
    method: "get",
    // noApi: true,
    params: query,
  });
}

// 溪下调流阀
export async function getXxeco(query) {
  return request(`/bs/openFromDt/xxeco`, {
    method: "get",
    // noApi: true,
    params: query,
  });
}

// 溪下溢洪道
export async function getXxSpillway(query) {
  return request(`/bs/spillway`, {
    method: "get",
    // noApi: true,
    params: query,
  });
}

// 钦寸(水量)
export async function getSpillway(query) {
  return request(`/bs/qcsk/spillway`, {
    method: "get",
    // noApi: true,
    params: query,
  });
}



// 南江下游流量(水量)
export async function getDownstreamFlow(query) {
  return request(`/bs/downstreamFlow`, {
    method: "get",
    // noApi: true,
    params: query,
  });
}


// 带可承受雨量的新接口
export async function getRainbear(query) {
  return request(`/rw/infopro/${query.rsvrCode}`, {
    method: "get",
    // noApi: true,
    params: query,
  });
}




// 认证是否白名单
export async function getSsoPass(query) {
  return request(`/sso/pass`, {
    method: "get",
    // noApi: true,
    params: query,
  });
}


// 获取用户信息
export async function getUserInfo(query) {
  return request(`/sso/userInfo`, {
    method: "get",
  });
}


// 溪下新泄洪洞接口
export async function getSpillwayTunnel(query) {
  return request(`/bs/spillwayTunnel`, {
    method: "get",
  });
}


// 溪下流量接口
export async function getDownstreamWater(query) {
  return request(`/bs/downstreamWater2`, {
    method: "get",
  });
}


// 嵊州第四水厂的数据
export async function getWaterSupplyOfSZ4(query) {
  return request(`/bs/waterSupplyOfSZ4`, {
    method: "get",
  });
}
