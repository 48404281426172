import Tit from "@/components/tit";
import React, { useState, useEffect } from "react";
import "./index.less";
import {
    getHourInfo,
    getRainInfo,
    getDayRainInfo,
    getTenDayInfo,
} from "@/api/rain";
import { useRecoilState, useRecoilValue } from "recoil";
import { rainInfo, configData } from "@/store/raininfo";
import moment from "moment";
import echarts from "echarts/lib/echarts";
import { setColor, setColorSw4 } from "@/utils/setColor";
import { keepTwoDecimalFull } from "@/utils/common";
function WaterLevel(params) {
    const [sumRainReal, setAreaData] = useState(0); // 表格数据存储
    const [yesWaterReal, setYesWaterReal] = useState({
        value: 0, //水位信息
        type: 0, // 趋势 0 不变 1上升 2下降
    }); // 昨日水位变化
    const [rainInfoData, setRainInfoData] = useRecoilState(rainInfo); // 雨水信息
    const [tenWater, setTenWater] = useState(0); //水位信息
    const [tenWaterQ, setTenWaterQ] = useState(0); // 趋势 0 不变 1上升 2下降
    const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
    const asyncFetchFun = () => {
        var xxsw,
            zcsw,
            minMaxRsvr = [],
            realArr = [], // 去掉val为null 实际列表
            tms = [],
            minMaxRain = [],
            tms2 = [],
            rains = [],
            sumRain = 0;
        const asyncFetch = async () => {
            const rsvrCode = '15084'
            await getRainInfo({ ID: rsvrCode })
                .then((res) => {
                    res.data.realtime.sskr = res.data.realtime.sskr.toFixed(0);
                    setRainInfoData(res.data.realtime);
                    if (res.data.realtime.sskr / res.data.realtime.xxkr > 0.8) {
                        xxsw = res.data.realtime.xxsw;
                    }
                    if (res.data.realtime.sskr / res.data.realtime.zckr > 0.8) {
                        zcsw = res.data.realtime.zcsw;
                    }
                    return res.data.realtime;
                })
                .then((res1) => {
                    getTenDayInfo({ ID: rsvrCode }).then((res) => {
                        const data = res.data;
                        const etWater = data.rsvrlist[9].val;
                        const stWater = res1.val;
                        if (etWater && stWater) {
                            if (stWater > etWater) {
                                setTenWater(Math.abs(etWater - stWater).toFixed(2));
                                setTenWaterQ(1); //上升
                            } else if (stWater == etWater) {
                                setTenWater(Math.abs(etWater - stWater).toFixed(2));
                                setTenWaterQ(0); //
                            } else {
                                setTenWater(Math.abs(etWater - stWater).toFixed(2));
                                setTenWaterQ(2); //下降
                            }
                        }
                        const nowrsvrlist = data.rsvrlist[0].val;
                        const prevsvrlist = data.rsvrlist[1].val;
                        const svrlistData = {
                            value: 0,
                            type: 0,
                        };
                        if (nowrsvrlist > prevsvrlist) {
                            svrlistData[`type`] = 1;
                        } else if (nowrsvrlist == prevsvrlist) {
                            svrlistData[`type`] = 0;
                        } else {
                            svrlistData[`type`] = 2;
                        }
                        svrlistData[`value`] = Math.abs(nowrsvrlist - prevsvrlist).toFixed(
                            2
                        );
                        setYesWaterReal(svrlistData);
                    });
                });

            // 获取水位过程
            await getHourInfo({ ID: rsvrCode }).then((res) => {
                res.data.result.forEach((item, index) => {
                    if (item.val) {
                        item.val = item.val
                        realArr.push(item);
                        minMaxRsvr.push(item.val);
                        tms.push(item.tm);
                    }
                });
                creatWaterLevelchart(res);
            });
            await getDayRainInfo({ ID: rsvrCode }).then(
                (res) => {
                    const data = res.data.result;
                    data.forEach((element) => {
                        tms2.push(element.tm);
                        rains.push(
                            null != element.valrain ? element.valrain.toFixed(1) : "-"
                        );
                        if (element.valrain) {
                            minMaxRain.push(element.valrain);
                            sumRain += element.valrain;
                        }
                    });
                    const maxLabeY = Math.max(...minMaxRain) + 6;
                    rains.map((item, index) => {
                        if (item == Math.max(...rains)) {
                            rains[index] = {
                                value: Number(item) ? Number(item).toFixed(1) : 0,
                                label: {
                                    show: true, //开启显示
                                    position: "bottom", //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: setColor(item).borderColor,
                                        fontSize: 12,
                                        fontWeight: 400,
                                    },
                                },
                            };
                        }
                    });
                    setAreaData(sumRain);
                    // creatMyChart(maxLabeY);
                }
            );
        };
        // const creatMyChart = (maxLabeY) => {
        //     // 初始化echarts实例
        //     const myChart = echarts.init(document.getElementById("myChart"));
        //     myChart.setOption({
        //         title: { text: "" },
        //         grid: {
        //             left: "9%",
        //             top: "18%",
        //             bottom: 10,
        //             right: 0,
        //         },
        //         tooltip: {
        //             trigger: "axis",
        //             formatter: function (params) {
        //                 return (
        //                     "时间：" +
        //                     moment(params[0].name).format("MM月DD日") +
        //                     "<br>降雨：" +
        //                     (isNaN(params[0].value)
        //                         ? "-"
        //                         : parseFloat(params[0].value).toFixed(1) + "mm")
        //                 );
        //             },
        //         },

        //         xAxis: {
        //             name: "mm",
        //             nameLocation: "start",
        //             nameTextStyle: {
        //                 color: "#68A8CC",
        //                 fontSize: 12,
        //                 align: "middle",
        //                 verticalAlign: "bottom",
        //                 padding: [0, 0, 10, 0],
        //             },
        //             axisTick: {
        //                 show: false,
        //             },
        //             data: tms2,
        //             position: "top",
        //             boundaryGap: ["20%", "20%"], //设置为true代表离零刻度间隔一段距离
        //             axisLabel: {
        //                 textStyle: {
        //                     color: "#68A8CC",
        //                     fontSize: 12,
        //                     margin: 10,
        //                 },

        //                 formatter: (val) => {
        //                     return moment(val).format("MM-DD");
        //                 },
        //                 alignWithLabel: true,
        //             },
        //             axisLine: {
        //                 show: false,
        //             },
        //             splitLine: {
        //                 show: false,
        //             },
        //         },
        //         yAxis: {
        //             axisTick: {
        //                 show: false,
        //             },
        //             type: "value",
        //             inverse: true,
        //             min: 0,
        //             max: maxLabeY,
        //             axisLabel: {
        //                 rotate: 0,
        //                 formatter: function (value, index) {
        //                     return value.toFixed(1);
        //                 },
        //                 textStyle: {
        //                     color: "#2D8BEE", //更改坐标轴文字颜色
        //                     fontSize: 10, //更改坐标轴文字大小
        //                 },
        //             },
        //             splitLine: { show: false },
        //             axisLine: {
        //                 lineStyle: {
        //                     color: "#E8E9F1",
        //                 },
        //             },
        //         },
        //         series: [
        //             {
        //                 barWidth: 20, //柱图宽度
        //                 barGap: 13, //柱图间距
        //                 name: "销量",
        //                 type: "bar",
        //                 data: rains,
        //                 color: "#6AA9FC",
        //                 itemStyle: {
        //                     normal: {
        //                         color: new echarts.graphic.LinearGradient(
        //                             0,
        //                             0,
        //                             0,
        //                             1, //渐变色在下面修改，这里是透明度
        //                             [
        //                                 {
        //                                     offset: 0,
        //                                     color: "#051934",
        //                                 },
        //                                 {
        //                                     offset: 1,
        //                                     color: "#67C7E8",
        //                                 },
        //                             ]
        //                         ),
        //                     },
        //                 },
        //             },
        //         ],
        //     });

        //     window.addEventListener("resize", () => {
        //         myChart.resize();
        //     });
        // };
        const creatWaterLevelchart = (res) => {
            // 初始化echarts实例
            try {
                const waterLevelChart = echarts.init(
                    document.getElementById("waterLevelChart2")
                );
                // 绘制图表参数设置
                const waterLevelChartOption = {
                    title: { text: "" },
                    grid: {
                        left: "9%",
                        top: 20,
                        bottom: "17%",
                        right: "5%",
                    },
                    tooltip: {
                        trigger: "axis",
                        formatter: function (params) {
                            return (
                                "时间：" +
                                moment(params[0].name).format("MM月DD日HH时") +
                                "<br>" +
                                params[0].seriesName +
                                "：" +
                                (isNaN(params[0].value)
                                    ? "-"
                                    : parseFloat(params[0].value).toFixed(2) + "m")
                            );
                        },
                    },
                    xAxis: [
                        {
                            axisTick: {
                                show: false,
                            },
                            boundaryGap: true,
                            show: true,
                            axisLine: {
                                onZero: false,
                                lineStyle: {
                                    color: "#E8E9F1",
                                },
                            },
                            data: tms,
                            axisLabel: {
                                interval: 25, // 自定义显示X轴坐标显示间隔
                                //rotate: 45,
                                textStyle: {
                                    color: "#68A8CC",
                                    fontSize: "12",
                                },
                                formatter: (val) => {
                                    return moment(val).format("MM-DD");
                                },
                            },
                            splitLine: { show: false },
                        },
                    ],
                    yAxis: [
                        {
                            name: "",
                            nameLocation: "end",
                            axisTick: {
                                show: false,
                            },
                            type: "value",
                            scale: true,
                            splitNumber: 4,
                            max:
                                (Math.max(...minMaxRsvr) < xxsw
                                    ? xxsw
                                    : Math.max(...minMaxRsvr)) + 2.8,
                            min:
                                (xxsw < Math.min(...minMaxRsvr)
                                    ? xxsw
                                    : Math.min(...minMaxRsvr)) - 2.8,
                            axisLabel: {
                                rotate: 0,
                                formatter: function (value, index) {
                                    return value.toFixed(1);
                                },
                                textStyle: {
                                    color: "#03ACBE", //更改坐标轴文字颜色
                                    fontSize: 10, //更改坐标轴文字大小
                                },
                            },
                            splitLine: { show: false },
                            axisLine: {
                                onZero: false,
                                lineStyle: {
                                    color: "#E8E9F1",
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            name: "水位",
                            type: "line",
                            animation: true,
                            symbol: "none",
                            areaStyle: {
                                color: {
                                    type: "linear",
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: "rgb(6,96,138)", // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: "rgb(8,35,69)", // 100% 处的颜色
                                        },
                                    ],
                                },
                            },
                            lineStyle: {
                                width: 1,
                                color: "#00D8FF", //改变曲线线颜色
                            },
                            mooth: true,
                            data: minMaxRsvr,
                            color: "#E5FCFE",
                            // itemStyle: {normal: {label: {show: true}}},
                            markLine: {
                                symbol: "none",
                                data: [
                                    {
                                        silent: true,
                                        symbol: "none",
                                        yAxis: Math.max(...minMaxRsvr),
                                        label: {
                                            position: "middle",
                                            formatter: `${keepTwoDecimalFull(Math.max(...minMaxRsvr), 2)}m`,
                                        },
                                        lineStyle: {
                                            type: "dashed",
                                            width: 1,
                                            color: "#4988FD", //改变曲线线颜色
                                        },
                                    },
                                ],
                            },
                            markPoint: {
                                data: [
                                    {
                                        symbol: "pin",
                                        symbolSize: 60,
                                        itemStyle: {
                                            color: setColorSw4(res.data.lastval).borderColor,
                                        },
                                        name: "当前水位",
                                        value: res.data.lastval,
                                        xAxis: res.data.lasttm,
                                        yAxis: res.data.lastval,
                                        label: {
                                            color: setColorSw4(res.data.lastval).labelColor,
                                            lineHeight: 22,
                                            fontSize: 12,
                                            verticalAlign: "middle",
                                        },
                                    },
                                    {},
                                ],
                                label: {
                                    normal: {
                                        formatter: function (res, index) {
                                            var value = res.data.value;
                                            return value.toFixed(2);
                                        },
                                    },
                                },
                            },
                        },
                    ],
                };
                const markLines = waterLevelChartOption.series[0].markLine.data;
                if (xxsw) {
                    markLines.push({
                        silent: true,
                        symbol: "none",
                        yAxis: xxsw,
                        label: {
                            position: "insideStartTop",
                            formatter: `台汛限制水位${xxsw ? xxsw.toFixed(2) : ""}m`,
                        },
                        lineStyle: {
                            type: "dashed",
                            width: 1,
                            color: "#FB4276", //改变曲线线颜色
                        },
                    });
                }

                // if (zcsw) {
                //   markLines.push({
                //     silent: true,
                //     symbol: ["none", "none"],
                //     yAxis: zcsw,
                //     type: "average",
                //     name: "正常水位",
                //     lineStyle: {
                //       //警戒线的样式  ，虚实  颜色
                //       type: "solid",
                //       color: "#FB4276",
                //     },
                //     label: {
                //       formatter: "正常水位(" + zcsw.toFixed(2) + " m)",
                //     },
                //   });
                // }
                waterLevelChart.setOption(waterLevelChartOption);
                window.addEventListener("resize", () => {
                    waterLevelChart.resize();
                });
            } catch (err) {
                console.log(err)
            }
        };
        asyncFetch();
    }
    useEffect(() => {
        asyncFetchFun();

    }, []);
    return (
        <div className="second-weather-wrap m-t-16 weather-wrap">
            <Tit tit="二级站水位" size="large">
                <div className="tit-r flex flex-a-c m-l-15">
                    {/* <div className="tit-r-item no-wrap">
                        降雨:<span className="c1 m-l-5">{sumRainReal.toFixed(1)} mm</span>
                    </div> */}
                    <div className="tit-r-item m-l-5 no-wrap">
                        水位:
                        <span className={tenWaterQ ? (tenWaterQ == 1 ? "s" : "j") : "c2"}>
                            {tenWaterQ ? (
                                <span
                                    className={`iconfont icon-ic_arrow_down1 m-l-5
              ${tenWaterQ == 1 ? "rotate" : ""}`}
                                />
                            ) : (
                                ""
                            )}
                            <span className="m-l-5">
                                {tenWater ? parseFloat(tenWater).toFixed(2) + " m" : "-"}
                            </span>
                        </span>
                    </div>
                    <div className="tit-r-item m-l-5 no-wrap">
                        昨日:
                        <span
                            className={
                                yesWaterReal.type ? (yesWaterReal.type == 1 ? "s" : "j") : "c2"
                            }
                        >
                            {yesWaterReal.type ? (
                                <span
                                    className={`iconfont icon-ic_arrow_down1 m-l-5
              ${yesWaterReal.type == 1 ? "rotate" : ""}`}
                                />
                            ) : (
                                ""
                            )}
                            <span className="m-l-5">
                                {yesWaterReal.value
                                    ? parseFloat(yesWaterReal.value).toFixed(2) + " m"
                                    : "-"}
                            </span>
                        </span>
                    </div>
                </div>
            </Tit>
            <div className="weather-main-wrap m-t-20 m-b-20 pos-r">
                <div className="area-wrap" id="waterLevelChart2"></div>
            </div>
        </div>
    );
}

export default WaterLevel;
