import React, { Suspense, useEffect } from "react";
import { rainInfo, configData } from "@/store/raininfo";
import { useRecoilValue, useRecoilState } from "recoil";
import { getRainInfo } from "@/api/rain";
import { keepTwoDecimalFull } from "@/utils/common";
import Tit from "@/components/tit";
import "./index.less";
const _ = require('lodash')
function RainInfo(params) {
    const [rainInfoData, setRainInfoData] = useRecoilState(rainInfo); // 雨水信息
    const configDataReal = useRecoilValue(configData); // 大屏数据所有配置信息
    const getRainInfoFun = () => {
        getRainInfo({ ID: configDataReal.config.rsvrCode })
            .then((res) => {
                const data = res.data
                data.realtime.sskr = keepTwoDecimalFull(data.realtime.sskr, 0);
                data.realtime.sskrBl = keepTwoDecimalFull(data.realtime.sskr / (data.period === 'zc' ? data.realtime.zckr : data.realtime.xxkr) * 100, 1);
                setRainInfoData(res.data.realtime);
                setTimeout(() => {
                    getRainInfoFun();
                }, 300000); //   5分钟接口单独请求一次
            }).catch(err => {
                setTimeout(() => {
                    getRainInfoFun();
                }, 300000); //   5分钟接口单独请求一次
            })
    }
    useEffect(() => {
        !_.isEmpty(configDataReal) && getRainInfoFun()
    }, [configDataReal])
    return (
        <Suspense fallback={<div></div>}>
            <div className="raininfo-wrap m-t-15">
                <Tit tit="水雨情信息" size="min"></Tit>
                <ul className="raininfo-ul m-t-20 after">
                    <li className="raininfo-li">
                        <div className="raininfo-li-image raininfo-li-image-1"></div>
                        <div className="raininfo-li-desc">
                            <dt>今日雨量</dt>
                            <dd>
                                <span>{parseFloat(rainInfoData.rain).toFixed(1)}</span>
                                mm
                            </dd>
                        </div>
                    </li>
                    <li className="raininfo-li">
                        <div className="raininfo-li-image raininfo-li-image-2"></div>
                        <div className="raininfo-li-desc">
                            <dt>水库水位</dt>
                            <dd>
                                <span>{parseFloat(rainInfoData.val).toFixed(2)}</span>m
                            </dd>
                        </div>
                    </li>
                    <li className="raininfo-li m-b-0">
                        <div className="raininfo-li-image raininfo-li-image-3"></div>
                        <div className="raininfo-li-desc">
                            <dt>水库库容</dt>
                            <dd>
                                <span>{parseFloat(rainInfoData.sskr).toFixed(0)}</span>万m³
                            </dd>
                        </div>
                    </li>
                    <li className="raininfo-li m-b-0">
                        <div className="raininfo-li-image raininfo-li-image-4"></div>
                        <div className="raininfo-li-desc">
                            <dt>限蓄库容比</dt>
                            <dd>
                                <span>
                                    {rainInfoData.sskrBl}
                                </span>
                                %
                            </dd>
                        </div>
                    </li>
                </ul>
            </div>
        </Suspense>
    );
}

export default RainInfo;
