import React, { useState } from "react";
import { keepTwoDecimalFull } from "@/utils/common";
import { gateTunne } from "@/store/raininfo";
import { useRecoilValue } from "recoil";
import Tit from "@/components/tit";
import "./index.less";
const _ = require("lodash");
const gateTunneCopy = {
  gateList: [
    {
      gateNo: 1,
      open: 0
    }, {
      gateNo: 2,
      open: 0
    }, {
      gateNo: 3,
      open: 0
    }, {
      gateNo: 4,
      open: 0
    }, {
      gateNo: 5,
      open: 0
    }, {
      gateNo: 6,
      open: 0
    }],
  amount: 0
}
function FloodWater(props) {
  // const gateTunneCopy = useRecoilValue(gateTunne); // 溢洪道 + 放空洞
  return (
    <div className="tx-floodWater-wrap m-t-10">
      <Tit tit="溢洪道">
        <div className="tit">
          <span>
            <i>{keepTwoDecimalFull(gateTunneCopy.amount * 3600 || 0, 1)}</i>
          </span>
          <span className="unit m-l-5">m³/s</span>
        </div>
      </Tit>
      <div className="tx-floodWater-wrap-main m-t-10 flex flex-j-b flex-a-c">
        <div className="tx-floodWater-wrap-main-l">
          <ul className="flex flex-j-b">
            {gateTunneCopy.gateList.map((item, index) =>
              <li key={index}>
                <dt>{item.gateNo}#弧门</dt>
                <dd>
                  <span>
                    <i>0</i>
                    {/* <i>{keepTwoDecimalFull(item.flow || 0, 1)}</i> */}
                  </span>
                  <span className="unit">m³/s</span>
                </dd>
                <dd className="ft-18">
                  <span>
                    <i>0</i>
                  </span>
                  <span className="unit">m</span>
                </dd>
              </li>
            )}
          </ul>
        </div>
        {/* <div className="tx-floodWater-wrap-main-r">
          <div className="tx-floodWater-wrap-main-r-c">
            <dt>放空洞1111111</dt>
            {gateTunneCopy.tunnelList.map((item, index) =>
              <>
                <dd key={index}>
                  <span>
                    <i>
                      {keepTwoDecimalFull(item.flow || 0, 1)}
                    </i>
                  </span>
                  <span className="unit">m³/s</span>
                </dd>
                <dd>
                  <span>
                    <i>{keepTwoDecimalFull(item.open || 0, 2)}</i>
                  </span>
                  <span className="unit">m</span>
                </dd>
              </>)}
          </div>
        </div> */}
      </div>
    </div>
  );
}
export default FloodWater;
