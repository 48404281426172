import React from "react";
import { Button } from "antd";
import "./index.less";
function BtnQc(props) {
    return (
        <div className="btnQc-wrap flex  m-t-20 flex-a-c w100 flex-j-c">
            <Button type="primary" ghost>切换</Button>
        </div>
    );
}

export default BtnQc;
