import React from "react";
import Tit from "@/components/tit";
import { settingData } from "@/store/raininfo";
import { convert } from "@/utils/common";
import { useRecoilValue } from "recoil";
import "./index.less";
/**
 *  设置类型 
 */
const setWaterQualityColor = (waterQuality) => {
    if (waterQuality < 3) {
        return 1
    }
    if (waterQuality < 5) {
        return 2
    }
    if (waterQuality < 5) {
        return 3
    }
}
function SignalLamp() {
    const settingDataReal = useRecoilValue(settingData);
    /**
     * 跳转界面 
     */
    const onClickItemFun = ({ url = '' }) => {
        window.location = url
    }
    return <div className="signalLamp-wrapper">
        <Tit tit="水库健康红绿灯" size="large"></Tit>
        <div className="lamp-list">
            <ul>
                <li className="lamp-item" onClick={() => {
                    onClickItemFun({ url: 'https://qcsk.zjszsk.com/project/login/auto/91' })
                }}>
                    <div className={`lamp-item-circular lamp-item-circular-1`}>
                        <div className={`lamp-item-main lamp-item-main-1 lamp-item-main-daba-1`}></div>
                    </div>
                    <div className={`lamp-item-title lamp-item-title-1`}>大坝</div>
                </li>
                <li className="lamp-item" onClick={() => {
                    onClickItemFun({ url: 'https://ls.app.19930322.com/?channelid=3e9b3774-78a1-4da7-8b92-c8d1b39aba60' })
                }}>
                    <div className={`lamp-item-circular lamp-item-circular-1`}>
                        <div className={`lamp-item-main lamp-item-main-1 lamp-item-main-dianzhan-1`}></div>
                    </div>
                    <div className={`lamp-item-title lamp-item-title-1`}>电站</div>
                </li>
                <li className="lamp-item" onClick={() => {
                    onClickItemFun({ url: 'https://ls.app.19930322.com/?channelid=ca7df37d-7d18-85b9-6567-fc3c9bc84a9c' })
                }}>
                    <div className={`lamp-item-circular lamp-item-circular-${setWaterQualityColor(settingDataReal.setting.waterQuality)}`}>
                        <div className={`lamp-item-main lamp-item-main-${setWaterQualityColor(settingDataReal.setting.waterQuality)} lamp-item-main-shuizhi-${setWaterQualityColor(settingDataReal.setting.waterQuality)}`}>
                            <div className="lamp-item-main-shuizhi-num">{convert(settingDataReal.setting.waterQuality)}类</div>
                        </div>
                    </div>
                    <div className={`lamp-item-title lamp-item-title-${setWaterQualityColor(settingDataReal.setting.waterQuality)}`}>水质</div>
                </li>
                <li className="lamp-item">
                    <div className={`lamp-item-circular lamp-item-circular-1`}>
                        <div className={`lamp-item-main lamp-item-main-1 lamp-item-main-kuqu-1`}></div>
                    </div>
                    <div className={`lamp-item-title lamp-item-title-1`}>库区</div>
                </li>
            </ul>
        </div>
    </div >
}
export default SignalLamp