/* eslint-disable react/style-prop-object */
import "./App.css";
import React, { useEffect, useState, useCallback } from "react";
import "@/assets/css/index.less";
import * as componentLib from "./module/index"; // 模块入口
import NewMenu from "@/module/newMenu";
import Video from "@/module/video";
import ImageWrap from "@/module/image";
import Time from "@/components/time";
import Logo from "@/components/logo";
import LoadedImg from "@/components/loadedImg"; // 预加载图片入口
import Navigation from "@/components/navigation";
import {
  titModel,
  mapModel,
  webscocketStatus,
  settingData,
  configData,
} from "@/store/raininfo";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { Button, Spin, Result, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as dd from "dingtalk-jsapi";
import { getSystemConfig, getSsoPass, getUserInfo } from "@/api/rain";
import { setAuthUrlFun } from "@/config";
import { getStorage, setStorage, GetRequest } from "@/utils/common";
const CHANNELID = GetRequest().channelid;
function App(props) {
  const [settingDataReal, setSettingDataReal] = useRecoilState(settingData); // 大屏数据配置信息
  const [configDataReal, setConfigDataReal] = useRecoilState(configData); // 大屏数据所有配置信息
  const showType = useRecoilValue(mapModel); // 2.显示地图 1.显示大坝
  const webscocketStatusType = useRecoilValue(webscocketStatus); // 信号Status
  const titType = useRecoilValue(titModel); // 2.党建 1.常规
  const [showBackBtn, setShowBackBtn] = useState(false); // 显示回车按钮
  const renderDataMap = (type) => {
    const dom = settingDataReal[`layOut`][type].map((item, index) => {
      if (!(item.componentName && componentLib[item.componentName])) {
        return null;
      }
      if (item.componentName == "Emergency") {
        const TagName = componentLib[`Emergency`];
        const TagName2 = componentLib[`WaterCategory`];
        return <TagName WaterCategory={<TagName2 />} />;
      } else {
        const TagName = componentLib[item.componentName];
        return <TagName key={index} />;
      }
    });
    return dom;
  };
  const bgImgFun = useCallback(() => {
    try {
      const headerBg = require(`Image/${settingDataReal.setting.headerBg ||
        "img_title_yueshui@2x.png"
        }`).default || ''
      return {
        backgroundImage: `url(${titType == 1
          ? headerBg
          : require("Image/img2.png").default
          })`,
      }
    } catch (err) {
      return {
        backgroundImage: `url(${require(`Image/${titType == 1 ? 'img_title_yueshui@2x' : 'img2'}.png`).default
          })`
      }
    }

  }, [settingDataReal, titType])
  /**
   * 获取配置 
   */
  const getSystemConfigFun = () => {
    getSystemConfig().then((res) => {
      setSettingDataReal(res.data.results.config_for_update);
      setConfigDataReal(res.data.results);
    });
  }
  /**
   * 获取用户信息 
   */
  const setUserInfoDataFun = (urlToken) => {
    getUserInfo().then((res) => {
      if (res.data.code != 1001) {
        if (res.data.code != 1008) {
          setStorage("token", res.data.token);
          getSystemConfigFun()
        } else {
          localStorage.removeItem("token")
          setAuthUrlFun()
        }
      } else {
        message("用户被禁用或未开通，请联系管理员")
      }
    }).catch(err => {
      if (err.data.code && err.data.code !== 1001) {
        setAuthUrlFun()
      }
    })
  }
  useEffect(() => {
    window.ontouchstart = (e) => {
      e.preventDefault()
    }
    if (dd.env.platform !== "notInDingTalk") {
      //进行钉钉登录操作
      setShowBackBtn(true);
    }
    if (!!CHANNELID) {
      getSsoPass({ channelid: CHANNELID }).then(res => {
        const results = res.data.results
        if (results) { // 白名单
          getSystemConfigFun()
        } else {
          const urlArr = GetRequest(); //  获取地址?后参数
          let urlToken = urlArr[`token`];
          if (getStorage("token")) {
            if (urlToken) {
              setStorage("token", urlToken);
            }
            setUserInfoDataFun(urlToken || getStorage("token"));
          } else {
            if (urlToken) {
              setStorage("token", urlToken);
              setUserInfoDataFun(urlToken);
            } else {
              setAuthUrlFun()
            }
          }
        }
      })


    }
    // document.addEventListener('touchstart', function (event) {
    //   event.preventDefault();
    // }, { passive: false });
  }, []);
  return !!settingDataReal ? (
    <div className={`container-wrap flex flex-colum container-wrap-bg container-wrap-bg-1`}>
      <NewMenu />
      {/*头部*/}
      <div className="header-wrap p-t-29">
        <div
          className={`header-wrap-bg`}
          style={bgImgFun()}
        >
          <div
            className={`wifi ${webscocketStatusType ? "has-wifi" : "no-wifi"}`}
          ></div>
          <Time></Time>
        </div>
      </div>
      {/*内容显示*/}
      <div className="flex container-bd-wrap flex-1">
        {/*左侧栏*/}
        {!!settingDataReal[`layOut`]["leftCom"].length && <div className="container-left-wrap m-l-28 flex">
          <div>
            {renderDataMap("leftCom")}
          </div>
        </div>}
        {/*中间侧栏*/}
        <div className="container-center-wrap flex1 flex flex-a-c flex-1 flex-j-c">
          <div className="flex-1 h100">
            {renderDataMap("centerCom")}
          </div>
        </div>
        {/*右侧侧栏*/}
        {!!settingDataReal[`layOut`]["leftCom"].length && <div className="container-right-wrap m-r-28  flex">
          <div>
            {renderDataMap("rightCom")}
            {/* <Logo /> */}
          </div>
        </div>}
        <Video />
        <ImageWrap />
      </div>
      <LoadedImg />
      {/*回撤导航*/}
      {GetRequest().show && <Navigation />}
      {showBackBtn ? (
        <Button
          type="primary"
          className={`back-btn ${configDataReal && configDataReal.corpid.indexOf('3306240001') != -1 ? 'back-btn-2' : ''}`}
          onClick={() => {
            if (dd.env.platform !== "notInDingTalk") {
              dd.biz.navigation.close();
            }
          }}
          size="large"
        >
          关闭
        </Button>
      ) : (
        ""
      )}
    </div>
  ) : !!CHANNELID ? (
    <div className="allWrap">
      <div className="allWrap-child">
        <Spin
          size="large"
          tip="Loading..."
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        ></Spin>
      </div>
    </div>
  ) : (
    <Result status="warning" title="无法获取到大屏对应的channelid" />
  );
}

export default App;
