import React, { useState, useEffect } from "react";
import { getGeneratedEnergy, getPowerGenerator, getEmptyTunnels, getDeviceDetails } from "@/api/union";
import { keepTwoDecimalFull } from "@/utils/common";
import Tit from "@/components/tit";
import Style from "./index.module.less";
const _ = require("lodash");
function Power(props) {
    // const setGateTunneCopy = useSetRecoilState(gateTunne); // 溢洪道 + 放空洞 
    // const setNowFlowCopy = useSetRecoilState(nowFlow); // 下泄总量
    const [title, setTitle] = useState(''); // 标题
    const [generatorDetails, setGeneratorDetails] = useState([]); // 发电机组
    const [emptyTunnels, setEmptyTunnels] = useState([]); // 放空洞
    const [ecoFlow, setEcoFlow] = useState([]); //  生态管 
    const [spillwayTunnel, setSpillwayTunnel] = useState([]); //  泄洪洞 
    const [amount, setAmount] = useState(0); // 总流量
    // const [powerGenerationDaily, setPowerGenerationDaily] = useState([]);
    /**
     * 获取发电机信息
     */
    const getPowerGeneratorFun = async () => {
        const res1 = await getDeviceDetails() // 获取闸门信息
        let amountCopy = 0
        if (res1.data.results) {
            let results = res1.data.results[`${props.typeName}`]
            if (results.powerGenerator) { // 发电机组
                results.powerGenerator.map((item) => {
                    amountCopy += item.flow
                })
                setGeneratorDetails(_.sortBy(results.powerGenerator, (item) => {
                    return item.generatorNo;
                }))
            }
            if (results.emptyTunnels) { // 放空洞
                results.emptyTunnels.map((item) => {
                    amountCopy += item.flow
                })
                setEmptyTunnels(_.sortBy(results.emptyTunnels, (item) => {
                    return item.seqNo;
                }))
            }
            if (results.ecoFlow) { //  生态管 
                results.ecoFlow.map((item) => {
                    amountCopy += (item.flowSecond || 0)
                })
                setEcoFlow(results.ecoFlow);
            }
            if (results.spillwayTunnel) { //  泄洪洞 
                results.spillwayTunnel.map((item) => {
                    amountCopy += item.flow
                })
                setSpillwayTunnel(_.sortBy(results.spillwayTunnel, (item) => {
                    return item.seqNo;
                }))
            }
            setAmount(amountCopy)
        }

        // getGeneratedEnergy().then(res => {  //统计
        //     const results = res.data.results
        //     if (results) {
        //         setPowerGenerationDaily(results[`${props.typeName}`])
        //     }
        // }) // 发电机统计
        setTimeout(() => {
            getPowerGeneratorFun();
        }, 300000); //   5分钟接口单独请求一次
    };
    useEffect(() => {
        setTitle(props.typeName == 'zgzsk' ? '发电机组 + 放空洞 + 生态管' : '泄洪洞 + 发电机组 + 放空洞')
        getPowerGeneratorFun();
    }, []);
    return (
        <div className={`${Style[`power-wrap-hs`]} flex-1`}>
            <Tit tit={title}>
                <div className="tit">
                    <span>
                        <i>{keepTwoDecimalFull(amount || 0, 1)}</i>
                    </span>
                    <span className="unit m-l-5">m³/s</span>
                </div>
            </Tit>
            <div className={`${Style[`power-main-wrap`]}`}>
                <ul className={`${Style[`fenche`]}`}>
                    {/*泄洪洞*/}
                    {spillwayTunnel.map((item, index) => {
                        return (
                            <li className={`${Style[`fenche-li`]} flex flex-j-c`} key={index}>
                                <div className={`${Style[`fenche-li-main`]}`}>
                                    <div className={`${Style[`fenche-li-hd`]} fz-12 theme-bg-color`} style={{ opacity: 0 }}>
                                        {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                        <span className="m-l-3">Kw</span>
                                    </div>
                                    <div className={`${Style[`fenche-li-bd`]}`}>
                                        {!item.flow ? (
                                            <>
                                                <div
                                                    className={`${Style[`op-7`]} ${Style[`op-7-${item.type}`]}`}
                                                    style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                                                >
                                                    <span className={`${Style[`fenche-desc`]} fz-12`}>{item.seqNo}#泄洪洞{props.typeName == 'zgzsk' ? '' : '(右)'}</span>
                                                </div>
                                                <span className={`${Style[`top-icon`]}`}></span>
                                            </>
                                        ) : (
                                            <div className={`h100 ${Style[`fenche-li-img`]}`}>
                                                <div className={`${Style[`fenche-li-img-gif`]}`}></div>
                                                <div className={`${Style[`fenche-li-desc`]} fz-12`}>{item.seqNo}泄洪洞</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`${Style[`fenche-li-ft`]} fz-12 theme-bg-color`}>
                                        {keepTwoDecimalFull(item.flow || 0, 1)}
                                        <span className="m-l-3">m³/s</span>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                    }
                    {/*发电机组*/}
                    {generatorDetails.map((item, index) => {
                        return (
                            <li className={`${Style[`fenche-li`]} flex flex-j-c`} key={index}>
                                <div className={`${Style[`fenche-li-main`]}`}>
                                    <div className={`${Style[`fenche-li-hd`]} fz-12 theme-bg-color`}>
                                        {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                        <span className="m-l-3">Kw</span>
                                    </div>
                                    <div
                                        className={`${Style[`fenche-li-bd`]} ${item.flow ? Style[`active`] : ""}`}
                                    >
                                        <div className={`${Style[`op-6`]} ${item.flow ? Style[`active`] : ""} `}>
                                            <span className={`${Style[`fenche-ani`]}`}></span>
                                            <span className={`${Style[`fenche-desc`]} fz-12`}>
                                                {item.generatorNo}#机组
                                            </span>
                                        </div>
                                        {item.flow ? "" : <span className={`${Style[`top-icon`]}`}></span>}
                                    </div>
                                    <div className={`${Style[`fenche-li-ft`]} fz-12 theme-bg-color`}>
                                        {keepTwoDecimalFull(item.flow || 0, 1)}
                                        <span className="m-l-3">m³/s</span>
                                    </div>
                                </div>
                            </li >
                        );
                    })}
                    {/*放空洞*/}
                    {emptyTunnels.map((item, index) => {
                        return (
                            <li className={`${Style[`fenche-li`]} flex flex-j-c`} key={index}>
                                <div className={`${Style[`fenche-li-main`]}`}>
                                    <div className={`${Style[`fenche-li-hd`]} fz-12 theme-bg-color`} style={{ opacity: 0 }}>
                                        {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                        <span className="m-l-3">Kw</span>
                                    </div>
                                    <div className={`${Style[`fenche-li-bd`]}`}>
                                        {!item.flow || (item.flow < 0.1) ? (
                                            <>
                                                <div
                                                    className={`${Style[`op-7`]} ${Style[`op-7-${item.type}`]}`}
                                                    style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                                                >
                                                    <span className={`${Style[`fenche-desc`]} fz-12`}>{props.typeName == 'zgzsk' ? `${item.seqNo}#放空洞` : `泄洪洞(左)`}</span>
                                                </div>
                                                <span className={`${Style[`top-icon`]}`}></span>
                                            </>
                                        ) : (
                                            <div className={`h100 ${Style[`fenche-li-img`]}`}>
                                                <div className={`${Style[`fenche-li-img-gif`]}`}></div>
                                                <div className={`${Style[`fenche-li-desc`]} fz-12`}>{props.typeName == 'zgzsk' ? `${item.seqNo}#放空洞` : `泄洪洞(左)`}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`${Style[`fenche-li-ft`]} fz-12 theme-bg-color`}>
                                        {keepTwoDecimalFull(item.flow || 0, 1)}
                                        <span className="m-l-3">m³/s</span>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                    }
                    {/*生态管*/}
                    {ecoFlow.map((item, index) => {
                        return (
                            <li className={`${Style[`fenche-li`]} flex flex-j-c`} key={index}>
                                <div className={`${Style[`fenche-li-main`]}`}>
                                    <div className={`${Style[`fenche-li-hd`]} fz-12 theme-bg-color`} style={{ opacity: 0 }}>
                                        {keepTwoDecimalFull(item.generatedOutput || 0, 0)}
                                        <span className="m-l-3">Kw</span>
                                    </div>
                                    <div className={`${Style[`fenche-li-bd`]}`}>
                                        {!item.flowSecond ? (
                                            <>
                                                <div
                                                    className={`${Style[`op-7`]} ${Style[`op-7-${item.type}`]}`}
                                                    style={{ opacity: item.type == 1 ? 0.6 : 1 }}
                                                >
                                                    <span className={`${Style[`fenche-desc`]} fz-12`}>生态管</span>
                                                </div>
                                                <span className={`${Style[`top-icon`]}`}></span>
                                            </>
                                        ) : (
                                            <div className={`h100 ${Style[`fenche-li-img`]}`}>
                                                <div className={`${Style[`fenche-li-img-gif`]}`}></div>
                                                <div className={`${Style[`fenche-li-desc`]} fz-12`}>生态管</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`${Style[`fenche-li-ft`]} fz-12 theme-bg-color`}>
                                        {keepTwoDecimalFull(item.flowSecond || 0, 1)}
                                        <span className="m-l-3">m³/s</span>
                                    </div>
                                </div>
                            </li>
                        );
                    })
                    }

                </ul >

            </div >
        </div >
    );
}

export default Power;
